import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { PartnerGalleryProps } from "./typings";
import { PartnerGallery as PartnerGalleryAd } from "@shared-ui/sponsored-content-partner-gallery";

export const PartnerGallery = withStores("context")(
  observer((props: PartnerGalleryProps) => {
    const { templateComponent, context } = props;

    const { metadata } = templateComponent;
    const { uci, pageName, variant } = templateComponent.config;
    const { id } = metadata;

    const inputs = {
      context: context,
      sponsoredContentContext: {
        pageName: pageName,
        sponsoredContentId: uci,
        variant: variant,
      },
    };

    return (
      <div id={id} data-testid="partner-gallery">
        <PartnerGalleryAd skipSsr inputs={inputs} adBlockCollapseExperiment="PG_Acceptable_Ads_Enablement" />
      </div>
    );
  })
);

export default PartnerGallery;
